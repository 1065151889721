<template>
  <el-dialog
    top="30px"
    fullscreen
    append-to-body
    :visible.sync="currentShowFlag"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @open="handleDialogOpen"
    @closed="handleDialogClosed"
  >
    <div slot="title">
      {{ $t('config_kpi.detailDialog') }}
    </div>
    <div>
      <simple-form
        ref="form"
        v-model="formModel"
        label-width="80px"
        :form-field="formField"
        :grid="{xs: 24, sm: 12, md: 8}"
        :view-flag="viewFlag"
      />
    </div>
    <!-- 公式编辑区域 -->
    <div
      v-show="!viewFlag"
      class="code-selector__wrapper"
    >
      <div
        v-for="(ele,index) in subjectData"
        :key="index"
        style="position:relative;margin:10px;"
        @mouseover="selectorMouseOverHandler(index)"
        @mouseleave="isShowDeleteButton=false"
      >
        <!-- 选择按钮 -->
        <el-button
          style="white-space:nowrap;"
          @click="showSubjectSelector"
        >
          {{ subjectData[index] && subjectData[index].subject ? subjectData[index].subject : $t('validate.notSelect') }}
        </el-button>
        <!-- 删除图标 -->
        <el-button
          v-show="isShowDeleteButton && index === currentIndex"
          style="position:absolute;right:-15px;"
          type="text"
          icon="el-icon-delete"
          @click="handleDeleteClick(index)"
        />
      </div>
      <!-- 新增按钮 -->
      <div
        class="add-button"
        @click="addSubjectCode"
      >
        +
      </div>
    </div>
    <div slot="footer">
      <el-button @click="handleDialogClose">
        {{ viewFlag ? $t('operation.close') : $t('operation.cancel') }}
      </el-button>
      <el-button
        v-if="!viewFlag"
        type="primary"
        @click="handleDataSubmit"
      >
        {{ $t('operation.submit') }}
      </el-button>
    </div>
    <selected-table
      :show-flag.sync="isShowSubjectSelector"
      :title="$t('config_kpi.formulaItem')"
      url="/jlr/mt/subjectCode/list"
      :table-column="subjectTableColumn"
      :search-form-field="subjectSearchFormField"
      :selected-data.sync="selectedData"
      @confirm="confirmHandler"
    />
  </el-dialog>
</template>
<script>
const BASEURL = {
  update: '/jlr/mt/kpi/update',
  get: '/jlr/mt/kpi/get',
  save: '/jlr/mt/kpi/save',
  subjectCode: '/jlr/mt/subjectCode/list'
}
export default {
  name: 'ConfigKpiDetail',
  props: {
    showFlag: { type: Boolean, default: false },
    viewFlag: { type: Boolean },
    detailData: { type: Object }
  },
  data () {
    return {
      currentIndex: null,
      formModel: {},
      isShowDeleteButton: false,
      isShowSubjectSelector: false,
      subjectData: [{}],
      subjectList: [],
      selectedData: []
    }
  },
  inject: ['handleDataSearch'],
  computed: {
    currentShowFlag: {
      get () {
        return this.showFlag
      },
      set (val) {
        this.$emit('update:showFlag', val)
      }
    },
    formField () {
      return [
        {
          prop: 'name',
          type: 'Input',
          labelWidth: '110px',
          label: this.$t('config_kpi.name'),
          component: { clearable: true },
          col: { xs: 8, sm: 8, md: 8 },
          rules: { trigger: 'blur', message: this.$t('validate.notBlank'), required: true }
        },
        {
          prop: 'code',
          type: 'Input',
          labelWidth: '110px',
          label: this.$t('config_kpi.code'),
          component: { clearable: true },
          col: { xs: 8, sm: 8, md: 8 },
          rules: { trigger: 'blur', message: this.$t('validate.notBlank'), required: true }
        },
        {
          prop: 'fluctuateChangeRateRule',
          type: 'NumberInput',
          labelWidth: '110px',
          label: this.$t('config_kpi.fluctuationRange'),
          component: { clearable: true },
          col: { xs: 8, sm: 8, md: 8 },
          rules: { trigger: 'blur', message: this.$t('validate.notBlank'), required: true }
        },
        {
          prop: 'thresholdChangeRateRule',
          type: 'NumberInput',
          labelWidth: '110px',
          label: this.$t('config_kpi.thresholdRange'),
          component: { clearable: true },
          col: { xs: 8, sm: 8, md: 8 },
          rules: { trigger: 'blur', message: this.$t('validate.notBlank'), required: true }
        },
        {
          prop: 'thresholdAbsoluteValue',
          type: 'NumberInput',
          labelWidth: '110px',
          label: this.$t('config_kpi.fluctuationAbsoluteValue'),
          component: { clearable: true, min: 0 },
          col: { xs: 8, sm: 8, md: 8 },
          rules: { trigger: 'blur', message: this.$t('validate.notBlank'), required: true }
        },
        {
          prop: 'isJoin',
          type: 'Select',
          labelWidth: '110px',
          label: this.$t('config_kpi.isJoin'),
          col: { xs: 8, sm: 8, md: 8 },
          component: { clearable: true, optionList: this.$getDictList('yes_no') },
          rules: { trigger: 'blur', message: this.$t('validate.notBlank'), required: true }
        },
        {
          prop: 'isSpecial',
          type: 'Select',
          labelWidth: '110px',
          label: this.$t('config_kpi.isSpecial'),
          col: { xs: 8, sm: 8, md: 8 },
          component: { clearable: true, optionList: this.$getDictList('yes_no') },
          rules: { trigger: 'blur', message: this.$t('validate.notBlank'), required: true }
        },
        {
          prop: 'businessArea',
          type: 'Select',
          labelWidth: '110px',
          label: this.$t('config_kpi.businessArea'),
          col: { xs: 8, sm: 8, md: 8 },
          component: { clearable: true, optionList: this.$getDictList('mt_kpi_business_area') },
          rules: { trigger: 'blur', message: this.$t('validate.notBlank'), required: true }
        },
        {
          prop: 'calculationFormulaCode',
          type: 'Input',
          labelWidth: '110px',
          label: this.$t('config_kpi.calculationFormulaCode'),
          component: { clearable: true, disabled: true },
          col: { xs: 24, sm: 24, md: 24 }
        },
        {
          prop: 'calculationFormula',
          type: 'Input',
          labelWidth: '110px',
          label: this.$t('config_kpi.calculationFormula'),
          component: { clearable: true, disabled: true },
          col: { xs: 24, sm: 24, md: 24 },
          rules: { trigger: 'blur', message: this.$t('config_kpi.formulaRequired'), required: true }
        },
        {
          prop: 'sort',
          type: 'NumberInput',
          labelWidth: '110px',
          label: this.$t('config_kpi.sort'),
          component: { clearable: true },
          col: { xs: 8, sm: 8, md: 8 },
          rules: { trigger: 'blur', message: this.$t('validate.notBlank'), required: true }
        }
      ]
    },
    subjectSearchFormField () {
      return [
        {
          prop: 'subject',
          type: 'Input',
          label: this.$t('config_kpi.name'),
          component: { clearable: true },
          col: { xs: 8, sm: 8, md: 8 }
        },
        {
          prop: 'subjectCode',
          type: 'Input',
          label: this.$t('config_kpi.code'),
          component: { clearable: true },
          col: { xs: 8, sm: 8, md: 8 }
        }
      ]
    },
    subjectTableColumn () {
      return [
        { prop: 'subject', label: this.$t('config_kpi.name'), minWidth: 120 },
        { prop: 'subjectCode', label: this.$t('config_kpi.code'), minWidth: 90 },
        { prop: 'type', label: this.$t('config_kpi.type'), minWidth: 90 },
        { prop: 'remark', label: this.$t('config_kpi.remark'), minWidth: 90 }
      ]
    }
  },
  methods: {
    handleDialogClose () {
      this.currentShowFlag = false
    },
    showSubjectSelector () {
      this.isShowSubjectSelector = true
    },
    setSubjectAndCode () {
      const calculationFormula = this.subjectData.map(ele => { return ele.subject }).join(' ')
      this.$set(this.formModel, 'calculationFormula', calculationFormula)
      const calculationFormulaCode = this.subjectData.map(ele => { return ele.subjectCode }).join(',')
      this.$set(this.formModel, 'calculationFormulaCode', calculationFormulaCode)
    },
    selectorChangeHandler (val, index) {
      this.$set(this.subjectData, index, val)
      this.setSubjectAndCode()
    },
    selectorMouseOverHandler (index) {
      this.currentIndex = index
      this.isShowDeleteButton = true
    },
    handleDeleteClick (index) {
      this.subjectData.splice(index, 1)
      this.setSubjectAndCode()
    },
    addSubjectCode () {
      this.subjectData.push({})
    },
    confirmHandler (data) {
      this.$set(this.subjectData, this.currentIndex, data[0])
      this.selectorChangeHandler(data[0], this.currentIndex)
    },
    handleDataSubmit () {
      this.$refs.form.$refs.simpleForm.validate(status => {
        if (status) {
          const loadingFlag = this.$loading({
            target: this.$el.querySelector('.el-dialog')
          })
          let submitModel = this.$_.cloneDeep(this.formModel)
          submitModel.calculationFormulaCode = this.subjectData.map(ele => { return ele.subjectCode }).join(',')
          submitModel.calculationFormula = this.subjectData.map(ele => { return ele.subject }).join(' ')
          this.$axios
            .post(
              this.detailData.id ? BASEURL.update : BASEURL.save,
              submitModel
            )
            .then(resp => {
              this.currentShowFlag = false
              this.$message({
                type: 'success',
                message: this.$t('tip.saveSuccess')
              })
              this.handleDataSearch()
            })
            .finally(() => {
              loadingFlag.close()
            })
        }
      })
    },

    handleDialogOpen () {
      this.subjectData = [{}]
      if (this.detailData.id) {
        const loadingFlag = this.$loading({ target: this.$el.querySelector('.el-dialog') })
        // 缓存字典
        this.$axios
          .post(BASEURL.subjectCode, {})
          .then(resp => {
            const respData = resp.data
            this.subjectList = respData.list
            // 请求数据
            this.$axios
              .post(BASEURL.get, { id: this.detailData.id })
              .then(resp => {
                const respData = resp.data
                this.formModel = respData
                const subjectCodes = respData.calculationFormulaCode.split(',')
                this.subjectData = []
                // 翻译
                subjectCodes.forEach(ele => {
                  const obj = this.subjectList.find(item => {
                    return item.subjectCode === ele
                  })
                  this.subjectData.push(obj)
                })
              })
              .finally(() => {
                loadingFlag.close()
              })
          })
      }
    },

    handleDialogClosed () {
      this.$refs.form.resetFields()
      this.formModel = {}
    }
  }
}
</script>
<style lang="less" scoped>
.code-selector__wrapper{
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.add-button {
  width: 28px;
  height: 28px;
  border: 1px dashed #ddd;
  border-radius: 2px;
  margin: 10px 12px;
  color: #aaa;
  font-size: 16px;
  text-align: center;
  line-height: 26px;
  &:hover {
    border: 1px dashed #66b1ff;
    color: #66b1ff;
    font-weight: 600;
    cursor: pointer;
  }
}
</style>
